const VERSION_2 = "v2";
const SHOP_THE_LOOK_PAGE = "landing_page_ecommerce";
const ZERO = 0;
const SIX = 6;
const LEFT = "left";
const ICON = "icon";
const RIGHT = "right";
const ARABIC = "ar-ae";
const ENGLISH = "en-us";
const TEXT_LEFT = " text-left";
const TEXT_RIGHT = "text-right";
const TEXT_CENTER = "text-center";
const WIDTH_200_PX = " w-52";
const WIDTH_IPAD_PRO = "787";
const LEARN_MORE = "Learn more";
const MOBILE_SCREEN = 500;
const TABLET_SCREEN = 900;
const FIFTY = 50;
const FOUR_HUNDRED = 400;
const PAGE_TITLE = "Wear That - ";
const BUTTON = "button";
const SR_ONLY = "sr-only";
const PADDING_LEFT_ZERO = "pl-0";
const PADDING_RIGHT_ZERO = "pr-0";
const GIFT_CARD_TEXT = "Gift Card";
const GIFT_CARD_TEXT_ARABIC = "كرت هدية";
const LOGIN_TEXT = "Login";
const LOGIN_TEXT_ARABIC = "تسجيل الدخول";
const SUBMIT_TYPE = "submit";
const BUTTON_TYPE = "button";
const TEXT_TYPE = "text";
const EMAIL_TYPE = "email";
const CHECKBOX_TYPE = "checkbox";
const RADIO_TYPE = "radio";
const WIDTH_113_PX = "w-[113px]";
const WIDTH_60_PERCENT = "w-[60%]";
const MINUS_ONE = "-1";
const ONE = "1";
const STYLING_SESSION_ENDPOINT = "/styling-session";
const SIGN_UP_ENDPOINT = "/survey-register";

const DATE_TEXT = "date";
const DEFAULT = "default";
const GIFT_CARD_ENDPOINT = "gift";
const HOTJAR_JS_PATH = "/JavaScript/hotjar.js";
const GOOGLE_OPTIMIZE_JS_PATH =
  "https://www.googleoptimize.com/optimize.js?id=OPT-TB2FPVP";
const GTM_JS_PATH = "/JavaScript/gtm.js";
const ORDER_A_LOOK = "order-a-look";
const API_BOOK_STYLING_SESSION = "/api/book-styling-session/";
const STYLING_SESSION_MAIL_SUBJECT = "CUSTOMER HAS BOOKED A STYLE SESSION";
const GIFT_CARD_BACKEND_ENDPOINT = "payment/gift/";
const NEWSLETTER_ENDPOINT = "communication/subscribe-newsletter/";
const GIFT_CARD_COLLECT_ENDPOINT = "payment/gift-collect";
const GIFT_CARD_SUCCESS_PAGE = "success/gift-card-success";
const GIFT_CARD_ERROR_PAGE = "failure/gift-card-failure";
const REFER_A_FRIEND_PAGE = "/referral";
const MY_REWARDS_LINK = "/dashboard/myRewards/";

const AMBASSADOR_PROGRAM_MAIL_SUBJECT = "New Ambassador Application Submitted";
const API_REGISTER_AMBASSADOR = "/api/apply-abbassador-program/";
const AMBASSADOR_REGISTRATION_SUCCESS_PAGE =
  "/success/ambassador-application-success";

const BLOG_MAIN_PAGE = "blog_main_page";
const SERENA = "serena";
const SERENA_PRODUCT_PAGE = "serena_product";
const AMBASSADOR_PROGRAM_PAGE = "ambassador_program";
const ABOUT_US_PAGE = "about_us";
const HOME_PAGE = "home";
const NEW_HOME_PAGE = "new_home";
const SA_HOW_IT_WORKS_PAGE = "sa_how_it_works";
const HOME_PAGE_V2 = "home_page_v2";
const IMAGE_BACKGROUND_STYLE = "bg-Secondary_Hover";

const VERTICAL_PADDING_TWO = "pt-4 pb-6";
const INPUT_FIELD_CLASS =
  "bg-transparent border border-New_Primary_Default focus:outline-none rounded w-[100%] h-[41px] px-4 ";
const NEW_INPUT_FIELD_CLASS =
  "bg-transparent border border-New_Primary_Default focus:outline-none w-[100%] h-[32px] px-3 ";
const INPUT_FIELD_ERROR =
  "bg-transparent border border-Error_Default focus:outline-none rounded w-[100%] h-[41px] px-4 ";

const PRIMARY_DEFAULT_COLOR = "black";
const AMBASSADOR_PROGRAM_PAGE_ENDPOINT = "/ambassador-program";
const REFERRAL_PAGE_TYPE = "refer_a_friend";
const FAQ_PAGE_TYPE = "faq_page";
const DASHBOARD_FAQ_PAGE_TYPE = "dashboard_faqs";
const BOX_ORDERED_PAGE_TYPE = "box_ordered";
const BOX_REPLACEMENT_PAGE_TYPE = "box_replacement_process";
const IN_STUDIO_STYLING_PAGE_TYPE = "your_in_studio_styling_session";
const RETURN_REQUEST_PAGE_TYPE = "return_request";
const DASHBOARD_FAQ_SLICE_TYPE = "dashboardFaqs";
const TAKE_STYLE_QUIZ = "Take The Style Quiz";
const TAKE_STYLE_QUIZ_AR = "قومي بإجراء اختبار تحديد الستايل";
const SHARE_ON_TEXT = "Share on";

const PROMO_BANNER_PAGES = [
  "home",
  "مسكن",
  "about us",
  "مَن نحن",
  "refer a friend",
  "توصية إحدى الصديقات",
  "ambassador program",
  "login",
  "forgot password",
];
const LAYOUT_RESPONSIVE = "responsive";
const LAYOUT_FILL = "fill";
const LOADER_STYLE =
  "flex justify-center align-middle items-center max-w-lg bg-Secondary_Background opacity-40";
const SURVEY_START_SECTIONS = {
  HomeBanner: "HomePageBanner",
  HowItWorks: "HowItWorks",
  HowWeWorks: "HowWeWorks",
  MeetOurStylists: "MeetOurStylists",
  HowToWearThat: "HowToWearThat",
  ThisOrThat: "ThisOrThat",
  FullyStyleInclusive: "FullyStyleInclusive",
  YourStyleYourWay: "YourStyleYourWay",
  Journal: "Journal",
};
const REFFERAL_CANDY_URL = "http://357wearthat.refr.cc/150aedoffpromo";
const CATEGORY_TEXT = "CATEGORY";
const SEARCH_TEXT = "Search";
const SUGGESTIONS_TEXT = "Suggestions";
const BLOGS_ADDRESS = "/blogs/";
const READ_MORE_TEXT = "Read More";
const NO_BLOGS_TEXT = "Sorry! there is no Blog to show right now.";
const SUBSCRIBE_TEXT = "Subscribe to our Newsletter";
const TRENDING_BLOGS_TEXT = "Trending Blogs";
const BLOG_SHARE_DESCRIPTION = "Read more blogs like this on Wearthat.me";
const WEARTHAT_PROD_ADDRESS = "wearthat.me";
const LIST_STYLE_DISC = " list-disc ";
const EAGER = "eager";
const LAZY = "lazy";
const MOBILE_SCREEN_BREAKPOINT = "1025";
const SMALL_SCREEN_BREAKPOINT = "1025";
const PROFILE_SETUP_TEXT = "Profile Setup";
const ACCOUNT_SETTINGS_TEXT = "Account Settings";

const PASSWORD_TEXT = "Password";
const ADDRESS_DETAILS = "Address Details";
const PAYMENT_AND_BILLINGS_TEXT = "Payment & Billings";
const LOGOUT_TEXT = "Logout";
const TYPE_PASSWORD = "password";

const MY_STYLE_PROFILE_TEXT = "My Style Profile";
const PREFERENCES_TEXT = "Profile";
const BOX_FREQUENCY_TEXT = "Box Frequency";
const PREFERENCE_TEXT = "Preference";
const ABOUT_YOU_TEXT = "About You";
const MY_DETAILS_TEXT = "My Details";
const SIZING_TEXT = "Sizing";
const BUDGET_TEXT = "Budget";
const MY_STYLE_PROFILE_ENDPOINT = "/dashboard/myStyleProfile";
const ACCOUNT_SETTINGS_ENDPOINT = "/dashboard/accountSettings";
const ADDRESS_DETAILS_ENDPOINT = "/dashboard/accountSettings/addressDetails";
const PAYMENT_DETAILS_ENDPOINT = "/dashboard/accountSettings/paymentDetails";
const ORDER_A_BOX_ENDPOINT = "/dashboard/orderABox";
const MY_REWARDS_TEXT = "My Rewards";
const ORDER_A_BOX = "Order A Box";
const REQUEST_A_BOX = "Request A Box";
const REWORK = "rework";
const STYLING_SESSION = "stylingSession";
const DUBAI = "Dubai";
const INSTRUCTION = "instruction";
const NEXT_STEP = "NextStep";
const LOCAL_STORAGE_DASHBOARD_SCREEN = "dashboard-previous-screen";
const ACCESS_TOKEN = "ACCESS_TOKEN";
const SUBMITTED_SURVEY = "submitted_survey";
const PAYMENT_STATUS = "payment_status";
const EMAIL_STATUS = "email_verifiction_status";
const USER_COUNTRY = "user_country";
const WARN_REFRESH =
  "Refreshing the page will cause problems with your process.";
const PROMO_BANNER = "promoBanner";
const FOOTER = "footer";
const SAUDI_ISO = "SA";
const DUBAI_ISO = "AE";
const SAUDI_ARABIA = "Saudi Arabia";
const UNITED_ARAB_EMIRATES = "United Arab Emirates";
const QUICK_SURVEY = "quick_survey";

export {
  QUICK_SURVEY,
  SAUDI_ARABIA,
  UNITED_ARAB_EMIRATES,
  DUBAI_ISO,
  SAUDI_ISO,
  USER_COUNTRY,
  PROMO_BANNER,
  FOOTER,
  WARN_REFRESH,
  REQUEST_A_BOX,
  ACCESS_TOKEN,
  SUBMITTED_SURVEY,
  PAYMENT_STATUS,
  EMAIL_STATUS,
  LOCAL_STORAGE_DASHBOARD_SCREEN,
  SERENA,
  SERENA_PRODUCT_PAGE,
  BOX_ORDERED_PAGE_TYPE,
  INSTRUCTION,
  NEXT_STEP,
  REWORK,
  STYLING_SESSION,
  DUBAI,
  ORDER_A_BOX,
  MY_REWARDS_TEXT,
  MY_STYLE_PROFILE_ENDPOINT,
  ACCOUNT_SETTINGS_ENDPOINT,
  ADDRESS_DETAILS_ENDPOINT,
  PAYMENT_DETAILS_ENDPOINT,
  ORDER_A_BOX_ENDPOINT,
  BOX_FREQUENCY_TEXT,
  BUDGET_TEXT,
  SIZING_TEXT,
  ABOUT_YOU_TEXT,
  PREFERENCE_TEXT,
  PREFERENCES_TEXT,
  MY_DETAILS_TEXT,
  MY_STYLE_PROFILE_TEXT,
  TYPE_PASSWORD,
  LOGOUT_TEXT,
  PAYMENT_AND_BILLINGS_TEXT,
  ADDRESS_DETAILS,
  PASSWORD_TEXT,
  PROFILE_SETUP_TEXT,
  ACCOUNT_SETTINGS_TEXT,
  SMALL_SCREEN_BREAKPOINT,
  MOBILE_SCREEN_BREAKPOINT,
  TAKE_STYLE_QUIZ_AR,
  SHARE_ON_TEXT,
  TAKE_STYLE_QUIZ,
  DEFAULT,
  VERTICAL_PADDING_TWO,
  IMAGE_BACKGROUND_STYLE,
  SUBMIT_TYPE,
  REFERRAL_PAGE_TYPE,
  EMAIL_TYPE,
  TEXT_TYPE,
  CHECKBOX_TYPE,
  BUTTON_TYPE,
  RADIO_TYPE,
  VERSION_2,
  ORDER_A_LOOK,
  SIGN_UP_ENDPOINT,
  SHOP_THE_LOOK_PAGE,
  BLOG_MAIN_PAGE,
  AMBASSADOR_PROGRAM_PAGE,
  GIFT_CARD_TEXT_ARABIC,
  LOGIN_TEXT_ARABIC,
  ZERO,
  SIX,
  LEFT,
  ICON,
  RIGHT,
  ARABIC,
  BUTTON,
  SR_ONLY,
  ENGLISH,
  TEXT_LEFT,
  TEXT_RIGHT,
  TEXT_CENTER,
  LEARN_MORE,
  WIDTH_200_PX,
  WIDTH_IPAD_PRO,
  MOBILE_SCREEN,
  LOGIN_TEXT,
  PAGE_TITLE,
  GIFT_CARD_TEXT,
  WIDTH_113_PX,
  MINUS_ONE,
  ONE,
  PADDING_LEFT_ZERO,
  WIDTH_60_PERCENT,
  STYLING_SESSION_ENDPOINT,
  DATE_TEXT,
  GIFT_CARD_ENDPOINT,
  HOTJAR_JS_PATH,
  GOOGLE_OPTIMIZE_JS_PATH,
  GTM_JS_PATH,
  TABLET_SCREEN,
  API_BOOK_STYLING_SESSION,
  STYLING_SESSION_MAIL_SUBJECT,
  GIFT_CARD_BACKEND_ENDPOINT,
  GIFT_CARD_COLLECT_ENDPOINT,
  GIFT_CARD_SUCCESS_PAGE,
  GIFT_CARD_ERROR_PAGE,
  REFER_A_FRIEND_PAGE,
  MY_REWARDS_LINK,
  AMBASSADOR_PROGRAM_MAIL_SUBJECT,
  PADDING_RIGHT_ZERO,
  API_REGISTER_AMBASSADOR,
  ABOUT_US_PAGE,
  AMBASSADOR_REGISTRATION_SUCCESS_PAGE,
  HOME_PAGE,
  NEW_HOME_PAGE,
  INPUT_FIELD_CLASS,
  NEW_INPUT_FIELD_CLASS,
  INPUT_FIELD_ERROR,
  FOUR_HUNDRED,
  FIFTY,
  PRIMARY_DEFAULT_COLOR,
  AMBASSADOR_PROGRAM_PAGE_ENDPOINT,
  SURVEY_START_SECTIONS,
  PROMO_BANNER_PAGES,
  LAYOUT_RESPONSIVE,
  LAYOUT_FILL,
  REFFERAL_CANDY_URL,
  FAQ_PAGE_TYPE,
  DASHBOARD_FAQ_PAGE_TYPE,
  DASHBOARD_FAQ_SLICE_TYPE,
  BOX_REPLACEMENT_PAGE_TYPE,
  IN_STUDIO_STYLING_PAGE_TYPE,
  RETURN_REQUEST_PAGE_TYPE,
  NEWSLETTER_ENDPOINT,
  HOME_PAGE_V2,
  CATEGORY_TEXT,
  SEARCH_TEXT,
  SUGGESTIONS_TEXT,
  BLOGS_ADDRESS,
  READ_MORE_TEXT,
  NO_BLOGS_TEXT,
  SUBSCRIBE_TEXT,
  TRENDING_BLOGS_TEXT,
  BLOG_SHARE_DESCRIPTION,
  WEARTHAT_PROD_ADDRESS,
  LIST_STYLE_DISC,
  EAGER,
  LAZY,
  LOADER_STYLE,
  SA_HOW_IT_WORKS_PAGE,
};

export const COUNTRY_LIST = [
  "Afghanistan",
  "Albania",
  "Algeria",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antigua & Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia",
  "Bosnia & Herzegovina",
  "Botswana",
  "Brazil",
  "British Virgin Islands",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Cape Verde",
  "Cayman Islands",
  "Chad",
  "Chile",
  "China",
  "Colombia",
  "Congo",
  "Cook Islands",
  "Costa Rica",
  "Cote D Ivoire",
  "Croatia",
  "Cruise Ship",
  "Cuba",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Estonia",
  "Ethiopia",
  "Falkland Islands",
  "Faroe Islands",
  "Fiji",
  "Finland",
  "France",
  "French Polynesia",
  "French West Indies",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea Bissau",
  "Guyana",
  "Haiti",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kuwait",
  "Kyrgyz Republic",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macau",
  "Macedonia",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Mauritania",
  "Mauritius",
  "Mexico",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Namibia",
  "Nepal",
  "Netherlands",
  "Netherlands Antilles",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Norway",
  "Oman",
  "Pakistan",
  "Palestine",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Reunion",
  "Romania",
  "Russia",
  "Rwanda",
  "Saint Pierre & Miquelon",
  "Samoa",
  "San Marino",
  "Satellite",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "South Africa",
  "South Korea",
  "Spain",
  "Sri Lanka",
  "St Kitts & Nevis",
  "St Lucia",
  "St Vincent",
  "St. Lucia",
  "Sudan",
  "Suriname",
  "Swaziland",
  "Sweden",
  "Switzerland",
  "Syria",
  "Taiwan",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Timor L'Este",
  "Togo",
  "Tonga",
  "Trinidad & Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks & Caicos",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "Uruguay",
  "Uzbekistan",
  "Venezuela",
  "Vietnam",
  "Virgin Islands (US)",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];
export const TIME_OPTIONS = {
  time: [
    "12:00 AM",
    "01:00 AM",
    "02:00 AM",
    "03:00 AM",
    "04:00 AM",
    "05:00 AM",
    "06:00 AM",
    "07:00 AM",
    "08:00 AM",
    "09:00 AM",
    "10:00 AM",
    "11:00 AM",
    "12:00 PM",
    "01:00 PM",
    "02:00 PM",
    "03:00 PM",
    "04:00 PM",
    "05:00 PM",
    "06:00 PM",
    "07:00 PM",
    "08:00 PM",
    "09:00 PM",
    "10:00 PM",
    "11:00 PM",
  ],
  values: [
    "00",
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
  ],
};
export const BLOG_CATEGORIES = [
  { name: "Top Trends" },
  { name: "Fashion" },
  { name: "Beauty" },
  { name: "Dresses" },
  { name: "Fashionista" },
];

export const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const PAGE_TITLES = {
  accountSettings: "Account Settings",
  faqs: "FAQs",
  myBoxes: "My Boxes",
  orderABox: "Order A Box",
  dashboard: "Dashboard",
  myRewards: "My Rewards",
  myStyleProfile: "My Style Profile",
  profileSetup: "Profile Setup",
  changePassword: "Change Password",
  addressDetails: "Address Details",
  paymentDetails: "Payment & Billing",
  myDetails: "My Details",
  budget: "Budget",
  preferences: "Preferences",
  profile: "Profile",
  sizing: "Sizing",
  boxFrequency: "Box Frequency",
  "payment-processing": "Payment Processing",
  scheduleBox: "Schedule Box",
  completed: "Completed",
  deliveredBoxes: "Delivered Boxes",
  returnRequest: "Return Request",
  returnRequested: "Return Requested",
  login: "Login",
  "survey-register": "Sign Up",
  forgotPassword: "Forgot Password",
};

export const PROMO_RESTRICTED_ROUTES = [
  "login",
  "forgotPassword",
  "survey-register",
  "survey",
  "checkout",
  "tamara",
  "sa-how-it-works",
];

export const FOOTER_RESTRICTED_ROUTES = [
  "login",
  "forgotPassword",
  "survey-register",
  "survey",
  "checkout",
  "sa-how-it-works",
];

export function getCurrency(countryCode) {
  switch (countryCode) {
    case "SA":
      return "SAR";
    case "AE":
      return "AED";
    default:
      return "AED";
  }
}
